@import "../../utils/variables";

.intro {
  width: 70%;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    font-size: 1rem;
    font-weight: 500;
    text-align: start;
    font-family: "Open Sans", serif;
  }

  h2 {
    font-size: 1rem;
    font-weight: 500;
    font-family: "Open Sans", serif;
    margin-top: .3rem;
  }

  h3 {
    margin-top: .3rem;
    font-size: 1rem;
    font-family: "Open Sans", serif;
    font-weight: 400;
    font-style: normal;
    color: $coffee;

  }
}