@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
$bistre: #3D211A;
$coffee: #6F4D38;
$chamoisee: #A07856;
$khaki: #CBB799;
$beige: #F5F5DC;

$small-radius: 4px;
$medium-radius: 8px;
$large-radius: 12px;

$fancy-font: 'Dancing Script', cursive;
$normal-font: 'Roboto', sans-serif;