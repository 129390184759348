@import "./utils/variables.scss";

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  font-family: $normal-font;
}

.app-context {
  width: 75%;
  min-width: 50rem;
  min-height: 100vh;
  background: transparentize($chamoisee, .9);
  box-shadow: 0 0 1rem 1rem transparentize($bistre, .9);
  backdrop-filter: blur(.5rem);
  border-left: 1px solid transparentize($coffee, .8);
  border-right: 1px solid transparentize($coffee, .8);
}
