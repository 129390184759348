@import "../../utils/variables.scss";

.cow {
  animation: cow 1.5s infinite;
  color: $coffee;
}

@keyframes cow {
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}