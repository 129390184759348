@import "../../utils/variables.scss";

.toolSelection {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;


    &-body {
      margin-top: 2rem;
      width: 50%;
      min-width: 40rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }

    &-header {
      margin-top: 5rem;

      .fancy-title {
        font-family: $fancy-font;
      }

      .fancy-subtitle {
        margin-top: .5rem;
      }
    }
  }

  .tool-info {
    background: #C5B7AD;
    box-shadow: 0 0 1rem rgba($coffee, .4);
    border-radius: $medium-radius;
    transition: .3s;
    cursor: pointer;

    &:hover {
      background: #bfac9e;
    }

    &-text {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      h6 {
        margin-left: 1rem;
      }
    }

    img {
      width: calc(100% - 2rem);
      height: 12rem;
      margin-top: 1rem;
      border-radius: $medium-radius;
      object-fit: cover;
    }
  }
}