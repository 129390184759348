.casino-page {
  .header {
    text-align: center;
    margin-top: 20px;
  }

  .results {
    margin-top: 20px;
    text-align: center;
  }

  .grid-cell {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
  }

  .winning-cell {
    background-color: #e0f7fa;
  }
}
