@import "../../utils/variables";

.needs-page {
  padding: 2rem;
  min-height: calc(100vh - 6rem);

  .header {
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      color: $bistre;
    }

    button {
      margin-top: 1rem;
      background-color: $coffee;
      color: white;

      &:hover {
        background-color: $chamoisee;
      }
    }
  }

  .needs-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;

    .needItem {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.page.needs {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.needs-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  background-color: #f0f0f0;
  z-index: -1;
  opacity: 0.25;
  overflow: hidden;
}

.icon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: $khaki;
  margin: 1rem;
  transition: transform 0.3s ease;
}

.needs-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;


  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }

}
