@import "../../utils/variables";

.needItem {
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  height: 20rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: $medium-radius;
  background: transparentize($chamoisee, .9);
  border: 1px solid transparentize($coffee, .5);

  &-image {
    height: 17rem;
    width: 100%;
    border-radius: $medium-radius;
    object-fit: cover;
  }

  &-text {
    margin-top: 1rem;
    width: 100%;
    color: $bistre;
    font-family: $fancy-font;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  .MuiChip-root {
    background-color: $coffee;
    color: white;
    padding: 0.5rem 1rem;
  }

  .MuiSvgIcon-root {
    font-size: 1.5rem;
  }

  &-modal {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .MuiBox-root {
      width: 80vw;
      display: flex;
    }

    &-text {
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
