@import "../../utils/variables";

.navbar {
  padding: 1rem 2rem;
  display: flex;

  .navbar-content {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    background-color: $coffee;
    padding: 0.5rem 2rem;
    border-radius: $medium-radius;
    color: $beige;
  }
}
